import React, { useState, useContext } from "react";
import "../css/TopNavbar.css";
import { Modal, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { NewContext } from "../utils/ContextProvider";

function TopNavbar() {
  const {
    getCompany,
    getRol,
    setFlag,
    flag,
    presente,
    contador,
    selectedEmployee,
  } = useContext(NewContext);

  const [modalAbierto, setModalAbierto] = useState(false);
  const ulrPicturePersonal = "https://www.softnik.cloud/awsProfile/getProfile";

  const handleCampanaClick = async () => {
    setModalAbierto(true);
  };

  return (
    <div className="navbar-inner">
      <div className="img-mobile">
        <img
          src={require("../image/Logo-softnik-positivo.png")}
          alt="Logo-softnik"
        />
      </div>

      <div className="sub-container-topbar">
        <h1>
          <span style={{ color: "rgb(156, 65, 156)" }}>¡Hola!</span>
          <span className="sub-container-name" style={{ color: "gray" }}>
            {" "}
            {selectedEmployee && `${selectedEmployee?.person.name} ${selectedEmployee?.person.lastname}`}
          </span>
        </h1>
      </div>

      <div className="container-button-user">
        {getRol !== "ROLE_USER" && (
          <div>
            <FontAwesomeIcon
              icon={faBell}
              style={{
                color: "rgb(63, 99, 172)",
                cursor: "pointer",
                fontSize: "22px",
                marginLeft: "20%",
              }}
              onClick={handleCampanaClick}
            />
            <div>
              {contador > 0 && presente && Object.keys(presente).length > 0 && (
                <span className="alert-contador" id="contador">
                  {contador}
                </span>
              )}
            </div>
            <Modal
              show={modalAbierto}
              onHide={() => setModalAbierto(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Asistencias</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Fecha</th>
                      <th>Hora Ingreso</th>
                      <th>Ciudad</th>
                      <th>Objetivo</th>
                      <th>Puesto</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {presente &&
                      presente.content &&
                      Array.isArray(presente.content) &&
                      presente.content.map((empleado, index) => (
                        <tr key={index}>
                          <td>
                            {empleado.name} {empleado.lastname}
                          </td>
                          <td>{empleado.date}</td>
                          <td>{empleado.time}</td>
                          <td>{empleado.city.name}</td>
                          <td>{empleado.objective.name}</td>
                          <td>{empleado.place.name}</td>
                          <td>{empleado.type == "I" ? "Ingreso" : "Egreso"}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setModalAbierto(false)}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}

        <div>
          <FontAwesomeIcon
            icon={faBars}
            className="icon-menu-topbar"
            onClick={() => setFlag(!flag)}
          />
          {selectedEmployee && (
            <img
              src={`${ulrPicturePersonal}?uuid=${selectedEmployee.id}&company=${getCompany}`}
              className="img-company"
              alt={`${getCompany}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TopNavbar;
