import React from 'react'
import '../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faXmark, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons'

const OptionComponentSolicitudes = ({value, onChange, setModalView, agregarFunction, modalView, setModalViewExport, modalViewExport}) => {
    return (
        <div className='contenedorOptions'>
          <input className='inputComponent' type='text' placeholder='Buscar por nombre, apellido o fecha' value={value} onChange={onChange}/>
          <div>
            <button className='button-icons-options' onClick={agregarFunction}>
              {
                !modalView ?
                  <FontAwesomeIcon icon={faPlus} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
            </button>
            <button className='button-icons-options' onClick={()=> {setModalViewExport(!modalViewExport); setModalView(false)}}>
            {
                !modalViewExport ?
                  <FontAwesomeIcon icon={faDownload} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
              
            </button>
            <button className='button-icons-options' onClick={()=> {setModalViewExport(!modalViewExport); setModalView(false)}}>
            {
                !modalViewExport ?
                  <FontAwesomeIcon icon={faUpload} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
              
            </button>
          </div>
        </div>
  )
}

export default OptionComponentSolicitudes
