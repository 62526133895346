// CustomPagination.js
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../css/CurrenPagination.css'

const CustomPagination = ({ currentPage, totalPages, setCurrentPage }) => {
  if(totalPages === 0) return null 
  return (
    <div className='contenedorPagination'>
      <p>{currentPage + 1} de</p>
      <p>{totalPages}</p>
      <button className={`${totalPages === 0 || currentPage === 0 ? 'buttonGrisPagination' : 'buttonAzulPagination'} buttonPagination`} onClick={() => setCurrentPage(currentPage - 1)}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <button className={`${totalPages === 0 ? 'buttonGrisPagination' : 'buttonAzulPagination'} buttonPagination`} onClick={() => setCurrentPage(currentPage + 1)}>
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

export default CustomPagination;
