// Solicitudes.jsx
import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Solicitudes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import AgregarSolicitudModal from "../forms/Solicitud/AgregarSolicitudModal";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getReason, getRequest } from "../utils/peticionesApi";
import OptionComponentSolicitudes from "../components/OptionComponentSolicitudes";

const Solicitudes = () => {
  const { getToken, getRol, getId } = useContext(NewContext);
  const [request, setRequest] = useState([]);
  const [reasons, setReason] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const [showModalSolicitud, setShowModalSolicitud] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuVisible && !event.target.closest(".menu-container")) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const openModalSolicitud = () => {
    setShowModalSolicitud(true);
  };

  const closeModalSolicitud = () => {
    setShowModalSolicitud(false);
  };

  const listarRequest = async () => {
    const response = await getRequest();
    setRequest(response.data);
  };

  const listarReason = async () => {
    const response = await getReason();
    setReason(response.data);
  };

  useEffect(() => {
    if (getToken && getId) {
      listarRequest();
      listarReason();
    }
  }, [searchTerm, getToken, showModalSolicitud]);

  const filtradoSolicitudes =
    request?.filter((request) => {
      return (
        request &&
        request.observations_request &&
        request.observations_request
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }) || [];

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) {
      return "Fecha inválida";
    }

    const [year, month, day] = dateArray;
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const handleIconClick = (index, id_request) => {
    /*   setShowOptions((prevOptions) => {
            return { ...prevOptions, [index]: !prevOptions[index] };
        });

        if (id_request) {
            listarSolicitudPorId(id_request);
        }
        openModalPorSolicitud(false); // Cierra el modal cuando se cierra el icono */
  };

  const TableRow = ({ request, handleIconClick }) => {
    // Buscar el motivo correspondiente al id_reason de la solicitud
    const motivo = reasons.find((reason) => reason.id === request.id_reason);

    // Obtener el nombre del motivo o 'Motivo Desconocido' si no se encuentra
    const motivoName = motivo ? motivo.reason : "Motivo Desconocido";

    return (
      <tr>
        <td>
          {request.employee_first_name + " " + request.employee_last_name}
        </td>
        <td>{motivoName}</td>
        <td>{formatDate(request.request_since)}</td>
        <td>{formatDate(request.request_expiration)}</td>
        <td>{request.observations_request}</td>
        <td style={{ color: request.active ? "#009188" : "#EC5B5B" }}>
          <span
            className={`${request.active ? "itemActive" : "itemInactive"
              }`}
          >
            {request.active ? "Aprobado" : "Pendiente"}
          </span>
          {/* {request.active ? "Aprobado" : "Pendiente"} */}
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            style={{
              color: "#828980",
              marginRight: "10px",
              fontSize: "1.5em",
              cursor: "pointer",
              marginLeft: request.active ? "25px" : "30px",
            }}
            onClick={() => handleIconClick(request.id_request)}
          />
        </td>
      </tr>
    );
  };

  const agregarFunction = () => {
    openModalSolicitud();
  };

  const handleSubmitSolicitud = async (
    request_since,
    request_expiration,
    observations_request,
    id_reason
  ) => {
    /*  try {
      const formDataToSendSolicitud = {
        active: false,
        id_reason: id_reason,
        observations_request: observations_request,
        request_expiration: request_expiration,
        request_since: request_since,
        uuid_employee: empleado_id,
      };

      // Perform the POST request
      await agregarSolicitud(formDataToSendSolicitud);
    } catch (error) {
      console.error("Error al agregar solicitud:", error);
    } */
  };

  return (
    <div className="contenedor-solicitudes">
      <div className="">
        <p className="titulo">
          {showModalSolicitud ? "Agregar solicitud" : "Solicitudes"}
        </p>

        {showModalSolicitud ? (
          <AgregarSolicitudModal
            reasons={reasons}
            rol={getRol}
            handleSubmitSolicitud={handleSubmitSolicitud}
            closeModalSolicitud={closeModalSolicitud}
          />
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="searchbar-input-wrap">
                {/* <Searchbar
                                    type="text"
                                    placeholder="Buscar por nombre , motivo o fecha"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    inputClassName="search-input"
                                    className="search-input"
                                />*/}
                <OptionComponentSolicitudes
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  agregarFunction={agregarFunction}
                />
              </div>
            </div>
            <div className="data-table">
              <table>
                <thead>
                  <tr>
                    <th className="label-cell" style={{ width: "5%" }}>
                      Solicitante
                    </th>
                    <th className="label-cell" style={{ width: "5%" }}>
                      Motivo
                    </th>
                    <th className="label-cell" style={{ width: "5%" }}>
                      Fecha desde
                    </th>
                    <th className="label-cell" style={{ width: "5%" }}>
                      Fecha hasta
                    </th>
                    <th className="label-cell" style={{ width: "5%" }}>
                      Observaciones
                    </th>
                    <th className="label-cell" style={{ width: "5%" }}>
                      Estado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtradoSolicitudes.length === 0 ? (
                    <tr>
                      <td colSpan="4">No se encontraron resultados.</td>
                    </tr>
                  ) : (
                    filtradoSolicitudes
                      ?.sort((a, b) => {
                        if (a.active === b.active) {
                          return 0;
                        } else if (a.active === false) {
                          return -1;
                        } else {
                          return 1;
                        }
                      })
                      .map((request, index) => (
                        <TableRow
                          key={index}
                          request={request}
                          index={index}
                          handleIconClick={handleIconClick}
                        />
                      ))
                  )}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <CustomPagination
                currentPage={currentPage}
                totalPages={0}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Solicitudes;
