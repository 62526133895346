import React from 'react'
import '../../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faXmark, faPlus, faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'

const OptionComponenteRegister = ({value, onChange, setModalAgregarPeriodo, modalAgregarPeriodo, setModalView, agregarFunction, modalView, setModalViewExport, modalViewExport, handleChangeFilter}) => {
  const month = new Date().getMonth()
  const options = [
    { label: 'Enero', value: 0 },
    { label: 'Febrero', value: 1 },
    { label: 'Marzo', value: 2 },
    { label: 'Abril', value: 3 },
    { label: 'Mayo', value: 4 },
    { label: 'Junio', value: 5 },
    { label: 'Julio', value: 6 },
    { label: 'Agosto', value: 7 },
    { label: 'Septiembre', value: 8 },
    { label: 'Octubre', value: 9 },
    { label: 'Noviembre', value: 10 },
    { label: 'Diciembre', value: 11 },
]

  const handleChange =(e)=> {
    handleChangeFilter(e.value)
  }

  return (
        <div className='contenedorOptions'>
          <div className='contenedorInputOptionRegisters'>
            <input className='inputComponentRegisters' type='text' placeholder='Buscar por nombre, apellido o DNI' value={value} onChange={onChange}/>
            <Select className='inputMothComponent' options={options} defaultValue={options[month]} onChange={handleChange}/>
          </div>
          <div>
          <button className='button-icons-options' onClick={()=> setModalAgregarPeriodo(!modalAgregarPeriodo)}>
              {
                !modalAgregarPeriodo ?
                <FontAwesomeIcon icon={faCalendarPlus} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
            </button>
            <button className='button-icons-options' onClick={agregarFunction}>
              {
                !modalView ?
                  <FontAwesomeIcon icon={faPlus} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
            </button>
            <button className='button-icons-options' onClick={()=> {setModalViewExport(!modalViewExport); setModalView(false)}}>
            {
                !modalViewExport ?
                  <FontAwesomeIcon icon={faDownload} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
              
            </button>
           
          </div>
        </div>
  )
}

export default OptionComponenteRegister
