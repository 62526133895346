import React, { useState } from "react";
import "../../css/AgregarVisitante.css";
import SelectEmpleados from "../../components/SelectEmpleados";
import Button from '../../components/Button'
import { postVisit, putVisit } from "../../utils/peticionesApi";

const AgregarVisitante = ({setModalView, visitaUpdate, listarVisitas}) => {
  const [dataVisit, setDataVisit] = useState({
    address: visitaUpdate ? visitaUpdate.address : "",
    entry_date_time: visitaUpdate ? `${visitaUpdate.entry_date} ${visitaUpdate.entry_time}` : "",
    lastname: visitaUpdate ? visitaUpdate.lastname : "",
    name: visitaUpdate ? visitaUpdate.name : "",
    national_id: visitaUpdate ? visitaUpdate.national_id : "",
    observations: visitaUpdate ? visitaUpdate.observations : "",
    uuid_employee: visitaUpdate ? visitaUpdate.uuid_employee : "",
    exit_date_time: visitaUpdate ? `${visitaUpdate.entry_date} ${visitaUpdate.exit_time}` : ''
  })
 
  const handleChange =(e)=>{
    setDataVisit({...dataVisit, [e.target.name]: e.target.value})
  }
  
  const handleChangeUuid =(empleadoUuid)=>{
    setDataVisit({...dataVisit, uuid_employee: empleadoUuid})
  }
  const handleSave = async ()=>{
      const dto = dataVisit
      const mesActual = new Date().getMonth() + 1
      const hora = new Date().getHours()
      const minutos = new Date().getMinutes()
      const segundos = new Date().getSeconds()

      dto.entry_date_time = `${new Date().getDate()}-${mesActual < 10 ? ('0' + mesActual) : mesActual}-${new Date().getFullYear()} ${hora < 10 ? ('0' + hora) : hora}:${minutos < 10 ? ('0' + minutos) : minutos}:${segundos < 10 ? ('0' + segundos) : segundos}`
     
      try {
        await postVisit(dataVisit)
        listarVisitas()
        setModalView(false)
      } catch (error) {
        console.log(error)
      }
    
  }

  const updateSave =async()=>{
    const dto = dataVisit
    dto.id = visitaUpdate.id
    dto.present_on_site = visitaUpdate.present_on_site
    
    try {
      await putVisit(dto)
      listarVisitas()
      setModalView(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="contenedorAgregarVisitante">
      <p className="tituloAgregarVisitante">Registro de visita</p>
      <div className="contenedorDatosVisitante">
        <div className="datosVisitante">
          <p>Nombre</p>
          <input name="name" type="text" placeholder="Nombre del visitante" value={dataVisit.name} onChange={handleChange}/>
        </div>
        <div className="datosVisitante">
          <p>Apellido</p>
          <input name="lastname" type="text" placeholder="Apellido del visitante" value={dataVisit.lastname} onChange={handleChange}/>
        </div>
        <div className="datosVisitante">
          <p>DNI</p>
          <input name="national_id" type="text" placeholder="DNI del visitante" value={dataVisit.national_id} onChange={handleChange}/>
        </div>
        <div className="datosVisitante">
          <p>Domicilio</p>
          <input name="address" type="text" placeholder="Direccion del visitante" value={dataVisit.address} onChange={handleChange}/>
        </div>
      </div>
      <div className="contenedorDatosVisitante">
          <div className="datosVisitanteEmpleado">
            <p>Visita a</p>
            <SelectEmpleados setUuid={handleChangeUuid} defaultValue={visitaUpdate?.uuid_employee}/>
          </div>
          <div className="datosVisitanteEmpleado">
            <p>Comentario</p>
            <textarea name="observations" value={dataVisit.observations} onChange={handleChange}></textarea>
          </div>
      </div>
     {/*  <div >
        <FileUploader />
      </div> */}
      <div className="contenedorButtonsAgrVisita">
        <Button tipe="sinBg" onClick={()=> setModalView(false)}>Cancelar</Button>
        <Button onClick={visitaUpdate ? updateSave : handleSave}>Guardar</Button>
      </div>
    </div>
  );
};

export default AgregarVisitante;
