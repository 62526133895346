import { React, useContext, useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import '../css/Empleado.css';
import { NewContext } from "../utils/ContextProvider";
import Button from './Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faAsterisk, faCheck, faEnvelope, faLocationDot, faPen, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getDocumentacion, getPersonaId, getReportes, getReportesAnnual } from "../utils/peticionesApi";
import EditEmpleado from "../forms/Empleado/EditEmpleado";

const Empleado = () => {
  const { getCompany, getId, getRol } = useContext(NewContext)
  const [persona, setPersona] = useState(null);
  const [documentacion, setDocumentacion] = useState(null);
  const [reporte, setReporte] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [detalleAsistencia, setDetalleAsistencia] = useState(false)

  const {id} = useParams()
  const ulrPicturePersonal = 'https://www.softnik.cloud/awsProfile/getProfile';
  const monthNames = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];

  const month = new Date().getMonth() + 1;
  const monthName = monthNames[month - 1]; // Restamos 1 porque los índices de arrays comienzan desde 0
  const annual = new Date().getFullYear();
  const handleEditClick = () => {
    setEditMode(true);
  };

  const listarPersona = async () => {
    const response = await getPersonaId(id)
    setPersona(response.data);
  };

  const obtenerReportes = async () => {
    if(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR'){
      try {
        if(!detalleAsistencia){
          const response = await getReportes(id, monthName)
          setReporte(response.data)
        }else{
          const response = await getReportesAnnual(id, annual.toString())
          setReporte(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const obtenerDocumentacion = async () => {
    try {
      const response = await getDocumentacion(id)
      if(response.status === 200) setDocumentacion(response.data);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if(id){
      listarPersona();
      obtenerReportes();
      obtenerDocumentacion();
    }
  }, [id, getRol, detalleAsistencia]);
 
  return (
      <div className="empleadoContainer">
          {editMode && <EditEmpleado setEditMode={setEditMode} persona={persona} id={id} setPersona={setPersona}/>}
          <div className="rectangulo">
            {
              getId &&
              <img
                alt='foto perfil'
                src={`${ulrPicturePersonal}?uuid=${id}&company=${getCompany}`}
              />
            }
            {persona && (
              <div className="contenedor-datos-empleado">
                <div className="contenedor-nombre-empleado">
                  <h2> {persona.person && `${persona.person.name} ${persona.person.lastname}`}</h2>
                  <div className="contenedorButtonsEmpleado">
                    <Button msj='Editar' onClick={handleEditClick}>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                    
                      <Button msj='Exportar'>
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Button>
                    
                   
                  </div>
                </div>
                <div className="datos-empleado">
                  <div>
                    <span>ESTADO</span>
                   {persona.status && (<p className={persona.status.active ? "status-activo" : "status-inactivo"}>{persona.status.active ? "Activo" : "Inactivo"}</p>)}
                  </div>
                  <div>
                    <span>CIUDAD</span>
                    <p>{persona.city && `${persona.city.name}`}</p>
                  </div>
                  <div>
                    <span>DNI</span>
                    <p>{persona.person && `${persona.person.national_id}`}</p>
                  </div>
                  <div>
                    <span>F.NAC</span>
                    <p>{persona.person && `${persona.person.date_of_birth}`}</p>
                  </div>
                </div>

              </div>
            )}

          </div>
          <div className="subdatos-empleado">
            <div>
              <div className="contenedor-item-datos">
                <FontAwesomeIcon icon={faLocationDot} className="icon-subdatos" />
                <p className="titulo-item-datos">Dirección</p>
              </div>
              <p className="contenido-item-datos">{persona?.address && `${persona.address.street} ${persona.address.number} Piso ${persona.address.floor} Departamento ${persona.address.apartament}`}</p>
            </div>
            <div>
              <div className="contenedor-item-datos">
              <FontAwesomeIcon icon={faPhone} className="icon-subdatos"/>
                <p className="titulo-item-datos">Teléfono</p>
              </div>
              <p className="contenido-item-datos">{persona?.cell_phone} </p>
            </div>
            <div>
              <div className="contenedor-item-datos">
              <FontAwesomeIcon icon={faEnvelope} className="icon-subdatos"/>
                <p className="titulo-item-datos">Mail</p>
              </div>
              <p className="contenido-item-datos">{persona?.email} </p>
            </div>
            <div>
              <div className="contenedor-item-datos">
              <FontAwesomeIcon icon={faAsterisk} className="icon-subdatos"/>
                <p className="titulo-item-datos">Contacto de emergencia</p>
              </div>
              <p className="contenido-item-datos">{persona?.name_emergency_contact}: {persona?.emergency_contact}</p>
            </div>
          </div>
          <div className="contenedorTituloAsistenciaHorario">
            <h2 className="titulo-asistencia-empleado">Asistencias y horarios</h2>
            <div className="contenedorBotonesFiltroAsistencia">
                    <button onClick={()=> setDetalleAsistencia(!detalleAsistencia)} className={`${!detalleAsistencia ? "buttonAsistenciaSelected" : "buttonAsistenciaNoSelected"}`}>Mes</button>
                    <button onClick={()=> setDetalleAsistencia(!detalleAsistencia)} className={`${detalleAsistencia ? "buttonAsistenciaSelected" : "buttonAsistenciaNoSelected"}`}>Año</button>
                  </div>
          </div>
          <div className="rectangulo3">
              {reporte && (
                <div className="cuadrado">
                  <div className="contenedor-porcential-titulo">
                    <h5>Ausencias</h5>
                    <p>Total de Ausencias: {reporte?.absences}</p>
                  </div>
                  <div className="contenedor-progress" style={{"--porcentual": reporte?.absences}}>
                    <svg width='50' height='50'>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                    </svg>
                    <span className="number-progress">{reporte?.absences}%</span>
                  </div>
                </div>
              )}
              {reporte && (
                <div className="cuadrado">
                  <div className="contenedor-porcential-titulo">
                    <h5>Llegadas tardes</h5>
                    <p>Total de llegadas tardes: {reporte?.lateArrivals}</p>
                  </div>
                  <div>
                  <div className="contenedor-progress" style={{"--porcentual": reporte?.lateArrivals}}>
                    <svg width='50' height='50'>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                    </svg>
                    <span className="number-progress">{reporte?.lateArrivals}%</span>
                  </div>
                  </div>
                </div>
              )}
              {reporte && (
                <div className="cuadrado">
                  <div className="contenedor-porcential-titulo">
                    <h5>Suspensiones</h5>
                    <p>Total de Suspensiones: {reporte?.suspension}</p>
                  </div>
                  <div>
                  <div className="contenedor-progress" style={{"--porcentual": reporte?.suspension}}>
                    <svg width='50' height='50'>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                    </svg>
                    <span className="number-progress">{reporte?.suspension}%</span>
                  </div>
                  </div>
                </div>
              )}
              {reporte && (
                <div  className="cuadrado">
                  <div className="contenedor-porcential-titulo">
                    <h5>Días trabajados</h5>
                    <p>Días trabajados: {reporte?.workedDays}</p>
                  </div>
                  <div>
                  <div className="contenedor-progress" style={{"--porcentual": reporte?.workedDays}}>
                    <svg width='50' height='50'>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                      <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                    </svg>
                    <span className="number-progress">{reporte?.workedDays}%</span>
                  </div>
                  </div>
                </div>
              )}
          </div>
          <h2 className="titulo-asistencia-empleado">Documentación</h2>
          {persona && documentacion ? (
            <div className="rectangulo4">
              <div className="cuadrados">
                <h5>Cursos</h5>
                <div>
                  <p>{documentacion.observations_course}</p>
                </div>
              </div>
              <div className="barra-lateral"></div>
              <div className="cuadrados">
                <h5>Psicofísico</h5>
                <div>
                  <p>{documentacion.observations_psychophysical}</p>
                </div>
              </div>
              <div className="barra-lateral"></div>
              <div className="cuadrados">
                <h5>Documentación</h5>
                <div>
                  <p>{documentacion.observations_rnr}</p>
                </div>
              </div>
            </div>
          ) :
          <div className="contenedor-sin-documentacion">
            <FontAwesomeIcon icon={faCheck} />
            <p>No hay documentación pendiente</p>
          </div>
          }
       
      </div>
  
  );
};

export default Empleado;