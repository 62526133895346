import axios from "axios";
import {
  URL_ASISTENCIA,
  URL_CITY,
  URL_DISTRICT,
  URL_DOCUMENTACION,
  URL_EMPLOYEE,
  URL_EXPORT_WITH_DETAIL,
  URL_EXPORT_WITHOUT_DETAIL,
  URL_OBJETIVOS,
  URL_PICTURE,
  URL_REASON,
  URL_REGISTERS,
  URL_REGSITRY,
  URL_REQUEST,
  URL_USER,
  URL_PLACE,
  URL_STADISTICS_MONTHLY,
  URL_STADISTICS_ANNUAL,
  URL_REGISTER,
  URL_PERIOD,
  URL_REGISTRYARRAY,
  URL_REGISTRYDELETE,
  URL_REGISTRYUPDATE,
  URL_VISIT,
} from "./urls";

const postUserLogin = ({ username, password }) => {
  return axios.post(
    URL_USER + "login",
    { username, password },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // Adjust the content type if needed
      }
    }
  );
};

const getRegisters = () => {
  return axios.get(URL_REGISTERS, {
    params: {
      page: 0,
      size: 500,
    },
  });
};

const getRegister =()=>{
  return axios.get(URL_REGISTER, {
    params: {
      page: 0,
      size: 500,
    },
  })
}

const getRegisterMonth = (month, year) => {
  return axios.get(`${URL_REGISTER}/monthly/${month}/${year}`);
}

const getRegisterEmployee = (EId, month) => {
  return axios.get(`${URL_REGISTER}/user/${EId}/monthly/${month}`, {
    params: {
      page: 0,
      size: 500,
    },
  });
}

/*
const getRegisterMonth =(month, year)=>{
  return axios.get(URL_REGISTER + `/allRegistryMonth/${month}/` + year)
}

const getRegisterEmployee =(EId, Month)=>{
  return axios.get(URL_REGISTER + `/${EId}/${Month}`, {
    params: {
      page: 0,
      size: 500,
    },
  })
}*/

const getUUID = (email) => {
  return axios.get(URL_EMPLOYEE + "/search/" + email, {
    params: {
      email: email,
    },
  });
};

const getDateUser = (username) => {
  return axios.get(URL_USER + username, {
    withCredentials: true,
  });
};

const postAsistencia = (dto) => {
  return axios(URL_ASISTENCIA, {
    data: JSON.stringify(dto),
    method: 'POST'
  });
};

const getAsistencia = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage
) => {
  return axios.get(URL_REGSITRY, {
    params: {
      fromDate: formattedDateInicio,
      toDate: formattedDateFin,
      page: currentPage,
      size: employeesPerPage,
    },
  });
};

const getListPersona = (id) => {
  return axios.get(URL_EMPLOYEE + "/" + id + "/profile", {
    params: {
      id: id,
    },
  });
};

const getPersonaId = (id) => {
  return axios.get(URL_EMPLOYEE + "/" + id);
}

const getDocumentacion =(id)=> {
  return axios.get(URL_DOCUMENTACION + '/' + id)
}

const getReportes =(id, monthName)=> {
  return axios.get(URL_STADISTICS_MONTHLY, {
    params: {
      id: id,
      month: monthName,
    }
  })
}

const getReportesAnnual =(id, monthName)=> {
  return axios.get(URL_STADISTICS_ANNUAL, {
    params: {
      id: id,
      year: monthName,
    }
  })
}

const getDistrito = (currentPage = 0) => {
  return axios.get(URL_DISTRICT, {
    params: {
      page: currentPage,
      size: 200,
    },
  });
};

const getCity = (currentPage = 0) => {
  return axios.get(URL_CITY, {
    params: {
      page: currentPage,
      size: 100,
    },
  });
};

const getPersona = (currentPage = 0, employeesPerPage = 2000) => {
  return axios.get(URL_EMPLOYEE, {
    params: {
      page: currentPage,
      size: employeesPerPage,
    },
  });
};

const postPicture =(formData, company, id)=> {
  return axios(URL_PICTURE + 'uploadProfile', formData, {
    params: {
      company: company,
      uuid: id
    },
    method: 'POST'
  })
}

const postUser = (formDataUser) => {
  return axios(URL_USER + "register", {
    data: JSON.stringify(formDataUser),
    method: 'POST'
  });
};

const postEmpleado =(dto)=>{
  return axios(URL_EMPLOYEE,{
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const getObjetivos =()=>{
  return axios.get(URL_OBJETIVOS, {
    params: {
      page: 0,
      size: 200,
    },
  })
}

const getObjetivo =(idO)=> {
  return axios.get(URL_OBJETIVOS + '/' + idO)
}

const getPuesto =(idObjetivo)=>{
  return axios.get(`${URL_PLACE}/${idObjetivo}/places`,{
    params: {
      id: idObjetivo,
    },
  })
}

const postObjetivo =(dto)=>{
  return axios(URL_OBJETIVOS,{
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const putObjetivo =(dto)=>{
  return axios(URL_OBJETIVOS,{
    data: JSON.stringify(dto),
    method: 'put'
  })
}

const deleteObjetivo =(idObjetivo)=>{
  return axios(URL_OBJETIVOS,{
    method: 'DELETE',
    params: {
      id: idObjetivo
    }
  })
}

const postPuesto = (dto) => {
  return axios(URL_PLACE,{
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const putPuesto = (dto) => {
  return axios(URL_PLACE,{
    data: JSON.stringify(dto),
    method: 'put'
  })
}

const getRequest = () => {
  return axios.get(URL_REQUEST)
}

const getReason =()=>{
  return axios.get(URL_REASON)
}

const postRequest =(dto)=>{
  return axios(URL_REQUEST, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const getMiPerfil =(idUser)=> {
  return axios.get(URL_EMPLOYEE + '/' + idUser + '/profile', {
    params: {
      id: idUser
      }
  })
}

const putPerfil =(id, data)=> {
  return axios(URL_EMPLOYEE + '/' + id, {
    data: data,
    method: 'put'
  })
}

const getExportWithDetail =(company, desde, hasta, uuid)=> {
  const url = uuid ? `${URL_EXPORT_WITH_DETAIL}s/${uuid}` : URL_EXPORT_WITH_DETAIL
  return axios.get(url,{
    params: {
      company,
      fromDate: desde,
      toDate: hasta
    },
    responseType: 'arraybuffer'
  })
}

const getExportWithOutDetail = (company, desde, hasta, uuid) => {
  const url = uuid ? `${URL_EXPORT_WITHOUT_DETAIL}/${uuid}` : URL_EXPORT_WITHOUT_DETAIL
  return axios.get(url,{
    params: {
      company,
      fromDate: desde,
      toDate: hasta
    },
    responseType: 'arraybuffer'
  })
}

const getPlaceObject =(id)=> {
  return axios.get(URL_PLACE + '/' + id + '/places')
}

const deletePlaceObject =(id)=> {
  return axios.delete(URL_PLACE + '/' + id)
}

const postPeriod =(dto)=>{
  return axios(URL_PERIOD,{
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const getPeriod =()=>{
  return axios.get(URL_PERIOD)
}

const postRegistryArray =(dto)=>{
  return axios(URL_REGISTRYARRAY,{
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const deleteRegistry = (dto) => {
  return axios.delete(URL_REGISTRYDELETE, {
    data: JSON.stringify(dto) 
  })
}

const putRegistry = (dto) => {
  return axios(URL_REGISTRYUPDATE, {
    data: dto,
    method: 'put'
  })
}

const getVisit = () => {
  return axios.get(URL_VISIT)
}

const postVisit = (dto) => {
  return axios(URL_VISIT, {
    data: dto,
    method: 'post'
  })
}

const putVisit = (dto) => {
  return axios(URL_VISIT, {
    data: dto,
    method: 'put'
  })
}

const deleteVisit =(id)=>{
  return axios.delete(URL_VISIT + '/' + id)
}

export {
  getMiPerfil,
  getRegisters,
  getRegister,
  getRegisterMonth,
  getRegisterEmployee,
  getUUID,
  getDateUser,
  getAsistencia,
  getListPersona,
  getPersonaId,
  getDocumentacion,
  getReportes,
  getReportesAnnual,
  getDistrito,
  getCity,
  getPersona,
  getObjetivos,
  getObjetivo,
  getPuesto,
  getRequest,
  getReason,
  getExportWithDetail,
  getExportWithOutDetail,
  getPlaceObject,
  getPeriod,
  getVisit,
  postUser,
  postPicture,
  postEmpleado,
  postAsistencia,
  postUserLogin,
  postObjetivo,
  postPuesto,
  postRequest,
  postPeriod,
  postRegistryArray,
  postVisit,
  deleteObjetivo,
  deletePlaceObject,
  deleteRegistry,
  deleteVisit,
  putPerfil,
  putObjetivo,
  putPuesto,
  putRegistry,
  putVisit,
};
