import React, { useContext, useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import '../../css/AgregarSolicitudModal.css'
import Button from '../../components/Button';
import Swal from 'sweetalert2';
import { getPersona, postRequest } from '../../utils/peticionesApi';
import { NewContext } from '../../utils/ContextProvider';

const AgregarSolicitudModal = ({
  reasons,
  rol,
  closeModalSolicitud
}) => {
  const {getAlert, getId, selectedEmployee} = useContext(NewContext)
  const [request_since, setStartDate] = useState('');
  const [request_expiration, setEndDate] = useState('');
  const [observations_request, setObservations] = useState('');
  const [id_reason, setSelectedMotivo] = useState('');
  const [selectedEmpleadoId, setSelectedEmpleadoId] = useState('');
  const [empleados, setEmpleados] = useState([]);
  const [nombre, setNombre] = useState('')

  useEffect(()=>{
    if(rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH'){
      listarEmpleados()
      }  else{
        setSelectedEmpleadoId(getId)
      }
  
  }, [])

  const handleMotivoChange = (event) => {
    setSelectedMotivo(event.target.value) 
  };

  const handleEmpleadoChange = (event) => {
    const {person, id} = empleados.find(empleado => empleado.id === event.target.value)
    console.log(person)
    setNombre({name: person.name, lastname: person.lastname, id})
    setSelectedEmpleadoId(event.target.value);
  };

  const listarEmpleados = async () => {
    const response = await getPersona()
    setEmpleados(response.data.content);
  };

  const agregarSolicitud = async () => {
    const formDataToSendSolicitud = {
      active: false,
      employee_first_name: nombre?.name,
      employee_last_name: nombre?.lastname,
      id_reason: id_reason,
      id_request: 0,
      observations_request: observations_request,
      request_expiration: request_expiration,
      request_since: request_since,
      uuid_employee: nombre?.id

  };

    try {
      console.log(formDataToSendSolicitud)
      const response = await postRequest(formDataToSendSolicitud)

      if (response.status === 200) {
        if (response.data.success) {
          getAlert({ msj: "Solicitud agregada con éxito.", tipe: "succes" });
          closeModalSolicitud();
        }
      } else {
        console.error("Hubo un problema al agregar el puesto.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
        <div className='contenedor-modal-solicitudes'>
          <div>
            {rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? (
              <div>
                <FormControl>
                  <InputLabel id="motivo-label">Empleados</InputLabel>
                  <Select
                    labelId="motivo-label"
                    id="empleados"
                    value={selectedEmpleadoId}
                    onChange={handleEmpleadoChange}
                    className="customSelect-solicitudes"
                  >
                    {empleados ? empleados.map(empleado => (
                      <MenuItem key={empleado.id} value={empleado.id}>
                        {empleado.person.name} {empleado.person.lastname}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div>
                <InputLabel id="motivo-label">Solicitante</InputLabel>
                <p>{selectedEmployee?.person.name} {selectedEmployee?.person.lastname}</p>
              </div>
            )}
          </div>
          <div>
            <TextField
              id="startDate"
              label="Fecha de inicio"
              type="date"
              value={request_since}
              onChange={(event) => setStartDate(event.target.value)}
              className="customSelect"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
           
            <TextField
              id="endDate"
              label="Fecha de fin"
              type="date"
              value={request_expiration}
              onChange={(event) => setEndDate(event.target.value)}
              className="customSelect"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
         
          <div className="selectContainer">
            <FormControl>
              <InputLabel id="motivo-label">Motivos</InputLabel>
              <Select
                labelId="motivo-label"
                id="motivo"
                value={id_reason}
                onChange={handleMotivoChange}
                className="customSelect-solicitudes"
              >
                {reasons ? reasons.map(reason => (
                  <MenuItem key={reason.id} value={reason.id}>{reason.reason}</MenuItem>
                )) : null}
              </Select>
            </FormControl>
          </div>
          
          <div>
            <TextField
              id="observations"
              label="Observaciones"
              multiline
              minRows={4}
              value={observations_request}
              onChange={(event) => setObservations(event.target.value)}
              className="customSelect"
            />
          </div>
        </div>
        <div className='button-solicitud'>
          <Button msj='Cancelar' tipe='sinbg' onClick={closeModalSolicitud} />
          <Button onClick={agregarSolicitud} msj='Agregar solicitud'/>
        </div>
        </>
  );
};

export default AgregarSolicitudModal;