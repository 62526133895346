import React, { useContext, useEffect, useState } from 'react'
import '../css/Registro.css'
import { getRegisterMonth, postPeriod } from '../utils/peticionesApi'
import TableRowRegister from '../forms/Registros/TableRowRegister'
import OptionComponenteRegister from '../forms/Registros/OptionComponentRegister'
import AgregarRegistro from '../forms/Registros/AgregarRegistro'
import Button from '../components/Button'
import { NewContext } from '../utils/ContextProvider'

const Registro = () => {
  const { month, setMonth } = useContext(NewContext);
  const [registros, setRegistros] = useState()
  const [filterRegister, setFilterRegister] = useState([])
  const [modalAgregarRegistro, setModalAgregarRegistro] = useState(false)
  const [modalAgregarPeriodo, setModalAgregarPeriodo] = useState(false)
  const [dataPeriodo, setDataPeriodo] = useState({
    name: '',
    end_time: '00:00',
    start_time: '00:00'
  })
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  useEffect(()=> {
    if(registros){
      filtroRegistros()
    }else{
      listarRegistros()
    }
  }, [registros])

  useEffect(()=>{
    listarRegistros()
  }, [month])
 
  const listarRegistros =async()=>{
    const monthSelected = months[month];
    const year = new Date().getFullYear()
    try {
      const response = await getRegisterMonth(monthSelected, year)
      
      setRegistros(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeFilter = (monthSelected) => {
    console.log(monthSelected)
    setMonth(monthSelected)
  }

  const filtroRegistros = () => {
    const groupedRegisters = {};

    registros?.forEach((item) => {
      const key = `${item.employeeId}_${item.objective?.id}_${item.place?.id}`;
    
      if (!groupedRegisters[key]) {
        groupedRegisters[key] = item;
      }
    });

    setFilterRegister(Object.values(groupedRegisters));
  }

  const agregarPuesto =async()=>{

    const data = {
      name: dataPeriodo.name,
      start_time: `${dataPeriodo.start_time}:00`,
      end_time: `${dataPeriodo.end_time}:00`
    }
    try {
      await postPeriod(data)
      setModalAgregarPeriodo(false)
    } catch (error) {
      console.log(error)
    }
  }
 
  const handleChangePeriodo =(e)=>{
    setDataPeriodo({...dataPeriodo, [e.target.name]: e.target.value})
  }

  if(modalAgregarRegistro) return <AgregarRegistro setModalAgregarRegistro={setModalAgregarRegistro}/>
 
  return (
    <div className='contenedorRegistro'>
      <p className='titulo'>Registro</p>
      <OptionComponenteRegister agregarFunction={()=> setModalAgregarRegistro(!modalAgregarRegistro)} setModalAgregarPeriodo={setModalAgregarPeriodo} modalAgregarPeriodo={modalAgregarPeriodo} handleChangeFilter={handleChangeFilter}/>
        {
              modalAgregarPeriodo ?
              <div className='modalAgregarPeriodo'>
                <p className='tituloAgregarPeriodo'>Agregar Periodo</p>
                <div>
                  <p>Nombre del periodo</p>
                  <input type='text' className='inputRegistro' value={dataPeriodo.name} name='name' onChange={handleChangePeriodo} placeholder='Ingrese el nombre'/>
                </div>
                <div className='row'>
                  <div>
                    <p>Inicio</p>
                    <input type='time' className='inputRegistro' value={dataPeriodo.start_time} name='start_time' onChange={handleChangePeriodo}/>
                  </div>
                  <div>
                    <p>Término</p>
                    <input type='time' className='inputRegistro' name='end_time' value={dataPeriodo.end_time} onChange={handleChangePeriodo} />
                  </div>
                </div>
                <div className='contenedorButtonAgregarPuesto'>
                  <Button tipe='violeta' msj='Agregar Puesto' onClick={agregarPuesto}/>
                </div>
              </div>
              :
              null
            }
      <div>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>DNI</th>
              <th>Objetivo</th>
              <th>Puesto</th>
            </tr>
          </thead>
          <tbody>
           
            {
              registros?.length === 0 ? 
              <tr><td>No hay registros para mostrar</td></tr> :
              filterRegister && filterRegister.map((item, index)=> {
                return <TableRowRegister key={index} EId={item.employeeId} objetivo={item.objective.name} puesto={item.place.name}/>
              })
            } 
           
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Registro
