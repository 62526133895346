import { useEffect, useState } from 'react'
import '../css/Visitantes.css'
import OptionCompoentetVisitantes from '../forms/Visitantes/OptionCompoentetVisitantes'
import AgregarVisitante from '../forms/Visitantes/AgregarVisitante'
import { deleteVisit, getPersona, getVisit, putVisit } from '../utils/peticionesApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonWalkingArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons'

const Visitantes = () => {
  const [modalView, setModalView] = useState(false)
  const [visitas, setVisitas] = useState()
  const [visitaUpdate, setVisitaUpdate] = useState()
  const [empleados, setEmpleados] = useState()

  useEffect(() => {
    listarVisitas()
    findEmpleados()
  }, [])

  const listarVisitas = async () => {
    try {
      const resp = await getVisit()
      setVisitas(resp.data)
    } catch (error) {
      console.log(error)
    }
  }

  const findEmpleados = async () => {
    const res = await getPersona()
    if (res && res.data.content) {
      const empleadosMap = res.data.content.reduce((acc, empleado) => {
        acc[empleado.id] = `${empleado.person.name} ${empleado.person.lastname}`;
        return acc;
      }, {});

      setEmpleados(empleadosMap);
    }

  }

  const formatTwoDigits = (num) => (num < 10 ? '0' + num : num);

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = formatTwoDigits(now.getDate());
    const month = formatTwoDigits(now.getMonth() + 1);
    const year = now.getFullYear();
    const hours = formatTwoDigits(now.getHours());
    const minutes = formatTwoDigits(now.getMinutes());
    const seconds = formatTwoDigits(now.getSeconds());
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const salidaVisitante = async (item) => {
    const datos = { ...item };
    datos.present_on_site = false;

    datos.exit_date_time = getFormattedDateTime();
    datos.entry_date_time = `${datos.entry_date} ${datos.entry_time}`;

    delete datos.entry_date;
    delete datos.entry_time;

    try {
      await putVisit(datos);
      listarVisitas();
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.response?.data || error.message);
    }
  };

  const updateVisita = (item) => {
    setVisitaUpdate(item)
    setModalView(true)
  }

  const deleteVisita = async (id) => {
    try {
      await deleteVisit(id)
      listarVisitas()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="visitantesContainer">
      <p className='titulo'>Visitantes</p>
      <OptionCompoentetVisitantes modalView={modalView} setModalView={setModalView} setVisitaUpdate={setVisitaUpdate} />
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>DNI</th>
            <th>Domicilio</th>
            <th>Ingreso</th>
            <th>Visita a</th>
            <th>Salida</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {visitas?.map((item, index) => {
            return (
              <tr key={index}>
                <td onClick={() => updateVisita(item)}>{item.name}</td>
                <td onClick={() => updateVisita(item)}>{item.lastname}</td>
                <td onClick={() => updateVisita(item)}>{item.national_id}</td>
                <td onClick={() => updateVisita(item)}>{item.address}</td>
                <td onClick={() => updateVisita(item)}>{item.entry_date} - {item.entry_time}</td>
                <td onClick={() => updateVisita(item)}>{empleados && empleados[item.uuid_employee] ? empleados[item.uuid_employee] : ''}</td>
                <td className='contenedor-item-salida'>
                  {
                    item.present_on_site ?
                      <FontAwesomeIcon onClick={() => salidaVisitante(item)} icon={faPersonWalkingArrowRight} className='icon-salida' />
                      :
                      <p>{item.exit_time}</p>

                  }
                </td>
                <td className='contenedor-item-eliminar'>
                  <FontAwesomeIcon icon={faTrash} className='icon-salida' onClick={() => deleteVisita(item.id)} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {
        modalView && <AgregarVisitante setModalView={setModalView} visitaUpdate={visitaUpdate} listarVisitas={listarVisitas} />
      }
    </div>
  )
}

export default Visitantes
