import { React, useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import "../css/Empleados.css";
import "../css/Home.css";
import "../css/Stepper.css";
import CustomPagination from "../components/CustomPagination";
import AgregarEmpleadoModal from "../forms/Empleado/AgregarEmpleadoModal";
import { NewContext } from "../utils/ContextProvider";
import {
  getPersona,
  postEmpleado,
  postPicture,
  postUser,
} from "../utils/peticionesApi";
import OptionComponenteEmpleado from "../components/OptionComponenteEmpleado";

const Empleado = () => {
  const { getToken, getCompany } = useContext(NewContext);
  const [personas, setPersonas] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmployee] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const employeesPerPage = 20; // Número de empleados por página
  const history = useNavigate();
  const Swal = require("sweetalert2");
  const [menuVisible, setMenuVisible] = useState(false);

  const listarPersonas = async (currentPage, employeesPerPage) => {
    try {
      const response = await getPersona(currentPage, employeesPerPage);
      setPersonas(response.data.content);
      setCurrentPage(currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  const agregarUser = async (formDataUser) => {
    try {
      const response = await postUser(formDataUser);
      if (response.status === 200) {
        if (response.data.success) {
          closeModal();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Empleado agregado con éxito.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        return response;
      } else {
        console.error("Hubo un problema al agregar el empleado.");
      }
    } catch (error) {
      console.error("Error al crear usuario:", error.response.data.message);
      Swal.fire({
        icon: "error",
        title: "Error al crear usuario:",
        text: error.response.data.message,
      });
      setError(error.response.data.message);
    }
  };

  const guardarFotoPerfil = async (id, file) => {
    if (!file) {
      console.error("Selecciona un archivo antes de importar.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postPicture(formData, getCompany, id);
      if (response.status === 200) {
        console.log("Imagen importada con éxito.");
      } else {
        console.error("Hubo un problema al importar la imagen.");
      }
    } catch (error) {
      console.error(
        "Error al importar la imagen:",
        error.response.data.message
      );
    }
  };

  useEffect(() => {
    if (!getToken) return;
    //listarPersonas(currentPage);

    if (searchTerm !== "") {
      listarPersonas(0, 300);
    } else {
      // Si no hay término de búsqueda, cargar todas las personas paginadas
      listarPersonas(currentPage, employeesPerPage);
    }
  }, [searchTerm, selectedEmployee, currentPage, getToken]);

  const filteredEmpleados =
    personas?.filter((empleado) => {
      const fullName = empleado.person.name + " " + empleado.person.lastname;
      return (
        fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        empleado.person.national_id.includes(searchTerm)
      );
    }) || [];

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const verEmpleado = (id) => {
    history(`/empleado/${id}`);
  };

  const [formData, setFormData] = useState({
    blood_group: "A*",
    cell_phone: "2223233232",
    address: {
      apartament: "",
      floor: 0,
      number: 0,
      street: "",
    },
    city: {
      id: 0,
      id_district: 0,
      name: "",
      zip_code: 0,
    },
    // emergency_contact: '',
    //name_emergency_contact: '',
    history: {
      comments: "",
      company: "",
      from_date: "",
      id: 0,
      reference: "",
      to_date: "",
    },
    person: {
      date_of_birth: "",
      lastname: "",
      name: "",
      national_id: "",
    },
    status: {
      active: true,
      comments: "",
      entry_date: "",
      leaving_date: "",
    },
    user: {
      company: getCompany,
      username: "",
      roles: "",
    },
    email: "",
  });

  const [formDataUser, setFormDataUser] = useState({
    username: "",
    email: "",
    password: "",
    active: true,
    company: getCompany,
    appUserRoles: ["ROLE_USER"],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const dateRegex = /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    // Validación del regex para el campo de fecha de nacimiento
    const isDateValid =
      name === "person.date_of_birth" ? dateRegex.test(value) : true;

    setFormData((prevFormData) => {
      // Realiza una copia profunda del objeto formData para mantener la inmutabilidad
      const updatedFormData = JSON.parse(JSON.stringify(prevFormData));

      // Divide el nombre del campo en partes
      const nameParts = name.split(".");

      // Recorre las partes para llegar al nivel correcto del objeto
      let currentLevel = updatedFormData;
      for (let i = 0; i < nameParts.length; i++) {
        const part = nameParts[i];

        if (i === nameParts.length - 1) {
          if (!currentLevel[part]) {
            currentLevel[part] = {}; // Inicializa el objeto si es undefined
          }

          currentLevel[part] = value;
        } else {
          if (!currentLevel[part]) {
            currentLevel[part] = {}; // Inicializa el objeto si es undefined
          }
          currentLevel = currentLevel[part];
        }
      }

      return updatedFormData;
    });
  };

  // Función para manejar el envío del formulario
  const handleSubmit = async (selectedImage) => {
    try {
      const formDataToSend = {
        address: {
          street: formData.address.street,
          number: formData.address.number,
          apartament: formData.address.apartament,
          floor: formData.address.floor,
        },
        blood_group: formData.blood_group,
        cell_phone: formData.cell_phone,
        city: {
          name: formData.city.name,
        },
        history: {
          comments: formData.history.comments,
          company: formData.history.company,
          from_date: formData.history.from_date,
          reference: formData.history.reference,
          to_date: formData.history.to_date,
        },
        person: {
          date_of_birth: formData.person.date_of_birth,
          lastname: formData.person.lastname,
          name: formData.person.name,
          national_id: formData.person.national_id,
        },
        // picture: {
        //   url: formData.picture.url,
        // },
        status: {
          active: formData.status.active,
          comments: formData.status.comments,
          entry_date: formData.status.entry_date,
          leaving_date: formData.status.leaving_date,
        },
        user: {
          company: getCompany,
          username: formData.user.username,
          // roles: formData.user.roles,
          roles: formData.user.roles,
        },
        email: formData.email,
      };
      const formDataToSendUser = {
        username: formData.email,
        email: formData.email,
        password: formData.person.national_id,
        active: true,
        company: getCompany,
        appUserRoles: ["ROLE_USER"],
      };
      console.log(formDataToSend, formDataToSendUser, selectedImage);

      const response = await agregarEmpleado(formDataToSend);
      if (response.data.success) {
        const responseUser = await agregarUser(formDataToSendUser);
        if (responseUser !== undefined) {
          const partes = response.data.details[0].split(":");
          const id = partes[1].trim();
          const responseFoto = await guardarFotoPerfil(id, selectedImage);
          if (
            response &&
            responseUser &&
            response.data &&
            response.data.details &&
            responseUser.data &&
            responseUser.data.details &&
            responseFoto &&
            responseFoto.data &&
            responseFoto.data.details
          ) {
            // const errors = response.data.details;
            // Convierte la matriz de errores en una cadena para mostrarla en la alerta
            // const errorMessage = errors.join('\n');
          } else {
            console.log("Hubo un problema al agregar el empleado.");
          }
        }
      } else {
        const errors = response.data.details;
        const errorMessage = errors.join("\n");
      }
    } catch (error) {
      console.error("Error al agregar el empleado:", error);
    }
  };

  const agregarEmpleado = async (dto) => {
    try {
      const response = await postEmpleado(dto);
      if (response.status === 200) {
        if (response.data.success) {
          closeModal();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Empleado agregado con éxito.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        return response;
      } else {
        console.error("Hubo un problema al agregar el empleado.");
      }
    } catch (error) {
      console.error(
        "Error al agregar el empleado:",
        error.response.data.details[0]
      );
      console.log("Respuesta con error:", error.response);
      setError(error.response.data.message);
    }
  };

  const resetForm = () => {
    setFormData({
      address: {
        apartament: "",
        floor: 0,
        number: 0,
        street: "",
      },
      blood_group: "",
      cell_phone: "",
      city: {
        id: 0,
        id_district: 0,
        name: "",
        zip_code: 0,
      },
      // emergency_contact: '',
      // name_emergency_contact: '',
      email: "",
      history: {
        comments: "",
        company: "",
        from_date: "",
        id: 0,
        reference: "",
        to_date: "",
      },
      person: {
        date_of_birth: "",
        lastname: "",
        name: "",
        national_id: "",
      },
      // picture: {
      //   url: 'https://www.google.com.ar/?hl=es"'
      // },
      status: {
        active: true,
        comments: "",
        entry_date: "",
        leaving_date: "",
      },
      user: {
        company: "",
        roles: "",
        username: "",
      },
    });
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuVisible && !event.target.closest(".menu-container")) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  const agregarFunction = () => {
    resetForm();
    setActiveStep(0);
    openModal();
  };

 

  return (
    <>
      <div className="employeecontainer">
        <p className="titulo">
          {showModal ? "Agregar empleado" : "Empleados"}
        </p>

        {showModal ? (
          <AgregarEmpleadoModal
            showModal={showModal}
            closeModal={closeModal}
            formData={formData}
            formDataUser={formDataUser}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            activeStep={activeStep}
            currentPage={currentPage}
            error={error}
          />
        ) : (
          <div className="data-table">
            <div style={{ display: "flex", alignItems: "center" }}>
              <OptionComponenteEmpleado
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                agregarFunction={agregarFunction}
              />
            </div>
            <table>
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Apellido</th>
                  <th scope="col">DNI</th>
                  <th scope="col">Fecha de nac.</th>
                  <th scope="col">Ciudad</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody  title="ver perfil">
                {searchTerm !== "" ? (
                  filteredEmpleados.length === 0 ? (
                    <tr>
                      <td colSpan="6">No se encontraron resultados.</td>
                    </tr>
                  ) : (
                    filteredEmpleados.map((empleado, index) => (
                      <tr
                        className="pointer"
                        key={index}
                        onClick={() => {
                          verEmpleado(empleado.id);
                        }}
                       
                      >
                        <td>{empleado.person.name}</td>
                        <td>{empleado.person.lastname}</td>
                        <td>{empleado.person.national_id}</td>
                        <td>{empleado.person.date_of_birth}</td>
                        <td>{empleado.city.name}</td>
                        <td>
                          <span
                            className={`${empleado.status.active ? 'itemActive' : 'itemInactive'}`}
                          >
                            {empleado.status.active ? "Activo" : "Inactivo"}
                          </span>
                        </td>
                      </tr>
                    ))
                  )
                ) : (
                  // Renderiza todos los empleados si no hay término de búsqueda
                  personas?.map((empleado, index) => (
                    <tr
                      className="pointer"
                      key={index}
                      onClick={() => {
                        verEmpleado(empleado.id);
                      }}
                    >
                      <td>{empleado.person.name}</td>
                      <td>{empleado.person.lastname}</td>
                      <td>{empleado.person.national_id}</td>
                      <td>{empleado.person.date_of_birth}</td>
                      <td>{empleado.city.name}</td>
                      <td className="contenedorItemEmpleado">
                      <span
                            className={`${empleado.status.active ? 'itemActive' : 'itemInactive'}`}
                          >
                            {empleado.status.active ? "Activo" : "Inactivo"}
                          </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center mt-4">
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Empleado;
